import {AxiosResponse} from "axios";
import * as _ from "lodash";
import {BaseAPIClient} from "./BaseAPIClient";

// import * as Models from "../models/Index";

export class APIClient extends BaseAPIClient{
    // static accessToken: Models.AccessToken | undefined;
    // ********************** //
    // ******* LEAGUES ****** //
    // ********************** //

    static async getLeagues() {
        const {client, config} = this.createClient();
        const url = `/api/leagues`;
        return await client.get(url, config)
            .then((httpResponse: AxiosResponse) => {
                return this.processResponse(httpResponse);
            })
            .catch((err) => {
                return this.processResponse(err.response);
            });
    }

    static async getLeagueStandings(leagueID: any, office: any) {
        const {client, config} = this.createClient();
        const url = `/api/leagues/${leagueID}/standings${!_.isNil(office) ? `?office=${office}` : ''}`;
        return await client.get(url, config)
            .then((httpResponse: AxiosResponse) => {
                return this.processResponse(httpResponse);
            })
            .catch((err) => {
                return this.processResponse(err.response);
            });
    }

    static async getLeagueMatchResults(leagueID: any) {
        const {client, config} = this.createClient();
        const url = `/api/leagues/${leagueID}/match_results`;
        return await client.get(url, config)
            .then((httpResponse: AxiosResponse) => {
                return this.processResponse(httpResponse);
            })
            .catch((err) => {
                return this.processResponse(err.response);
            });
    }

    static async getLeagueByID(leagueID: any) {
        const {client, config} = this.createClient();
        const url = `/api/leagues/${leagueID}`;
        return await client.get(url, config)
            .then((httpResponse: AxiosResponse) => {
                return this.processResponse(httpResponse);
            })
            .catch((err) => {
                return this.processResponse(err.response);
            });
    }

    static async getOverallStandings() {
        const {client, config} = this.createClient();
        const url = `/api/overall_standings`;
        return await client.get(url, config)
            .then((httpResponse: AxiosResponse) => {
                return this.processResponse(httpResponse);
            })
            .catch((err) => {
                return this.processResponse(err.response);
            });
    }

    static async getSignUpData() {
        const {client, config} = this.createClient();
        const url = `/api/signups`;
        return await client.get(url, config)
            .then((httpResponse: AxiosResponse) => {
                return this.processResponse(httpResponse);
            })
            .catch((err) => {
                return this.processResponse(err.response);
            });
    }

    static async submitMatch(match: any) {
        debugger;
        const {client, config} = this.createClient();
        const url = `/api/match_results`;
        return await client.post(url, match, config)
            .then((httpResponse: AxiosResponse) => {
                return this.processResponse(httpResponse);
            })
            .catch((err) => {
                return this.processResponse(err.response);
            });
    }

    static async submitSignUp(obj: any) {
        debugger;
        const {client, config} = this.createClient();
        const url = `/api/signups`;
        return await client.post(url, obj, config)
            .then((httpResponse: AxiosResponse) => {
                return this.processResponse(httpResponse);
            })
            .catch((err) => {
                return this.processResponse(err.response);
            });
    }
}

export default APIClient;