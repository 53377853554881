import * as React from "react";
import {Observer} from "mobx-react";
import { Icon } from "antd";
import {LoadingProps, LoadingState} from "./LoadingInterface";
import {BaseComponent} from "../BaseComponent";

export class Loading extends BaseComponent<LoadingProps, LoadingState> {
    protected renderContent() {
        return (
            <div className="loading-main-container">
                <Icon type="loading" />
            </div>
        )
    }

    render() {
        return(
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}