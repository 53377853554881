import * as React from "react";
import * as _ from "lodash";
import {Observer, observer} from "mobx-react";
import {StandingsProps, StandingsState} from "./StandingsInterface";
import {BaseComponent} from "../BaseComponent";
import * as ComponentFactory from "../ComponentFactory";
import {Icon, Input, Select} from "antd";

import {AppMode} from "../../stores/AppStore";

const {Option} = Select;

@observer
export class Standings extends BaseComponent<StandingsProps, StandingsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadingStandings: false,
            currentLeague: this.dataStore.selectedLeague,
            searchInputValue: undefined,
            currentOfficeFilter: 'all',
        }

        this.loadStandings = this.loadStandings.bind(this);
        this.standingsContent = this.standingsContent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.searchResults = this.searchResults.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        this.loadStandings(this.props.league);
    }

    componentDidUpdate() {
        const {dataStore, state, props} = this;
        if (_.isNil(state.currentLeague) || state.currentLeague.id !== props.league.id) {
            dataStore.clearStandings();
            this.setState({
                currentLeague: props.league,
                searchInputValue: undefined,
            }, () => {
               this.loadStandings(props.league);
            });
        }
    }

    loadStandings(league: any) {
        const {dataStore, state} = this;
        const {currentOfficeFilter} = state;
        const {shouldFetchStandings} = dataStore;

        const office = currentOfficeFilter !== 'all' ? currentOfficeFilter : undefined;

        if (!state.loadingStandings && shouldFetchStandings) {
            this.setState({
                loadingStandings: true,
            }, () => {
                dataStore.getLeagueStandings(league.id, office)
                    .then(res => {
                        if (res.success) {
                            this.setState({
                                loadingStandings: false,
                                currentLeague: league,
                            })
                        } else {
                            this.appStore.showMessage('error', res.statusText);
                        }
                    });
            });
        }
    }

    handleSearchChange(event: any) {
        this.setState({
            searchInputValue: event.target.value,
        })
    }

    searchResults(standings: any) {
        const {state} = this;

        const r = standings!.filter((standing: any) => {
            const search = !_.isNil(state.searchInputValue) ? state.searchInputValue.toLowerCase() : "";
            if (standing.first_name!.toLowerCase().includes(search) ||
                standing.last_name!.toLowerCase().includes(search)) {
                return standing;
            } else return undefined
        });

        return r;
    }

    handleFilterChange(event: any) {
        const {state} = this;
        const {currentLeague} = state;
        this.setState({currentOfficeFilter: event}, () => this.loadStandings(currentLeague));
    }

    standingsContent() {
        const {dataStore, state} = this;
        const {currentOfficeFilter} = state;
        const {standings} = dataStore;
        const isMobile = this.appStore.state.mode === AppMode.Mobile;
        const results = _.isNil(state.searchInputValue) ? standings : this.searchResults(standings);

        return (
            <div className={`standings-main-container ${isMobile ? "mobile" : ""}`}>
                <div className={"standings-filter-container"}>
                    <Input className={`match-results-input ${isMobile ? "mobile" : ""}`}
                           placeholder={"Search Standings"}
                           value={this.state.searchInputValue}
                           onChange={(e) => {this.handleSearchChange(e)}}
                           suffix={_.isNil(this.state.searchInputValue) ? <Icon type={"search"} /> : <Icon type={"close"} className={"standings-input-clear"} onClick={() => this.setState({searchInputValue: undefined})} />}
                    />
                    <div>
                        <label>Office:</label>
                        <Select className={"standings-filter"} defaultValue={currentOfficeFilter} onChange={(e: any) => this.handleFilterChange(e)}>
                            <Option value={"all"}>All</Option>
                            <Option value={"Boston"}>Boston</Option>
                            <Option value={"Chicago"}>Chicago</Option>
                        </Select>
                    </div>
                </div>
                {!_.isNil(results) && results.length > 0 && results.map((standing: any, index: number) => <ComponentFactory.StandingItem key={index} standing={standing}/>)}
                {(_.isNil(results) || results.length === 0) &&  <h3> No Standings Found...</h3> }
            </div>
        )
    };

    protected renderContent() {
        const {state} = this;
        const {loadingStandings} = state;

        return (
            <>
                {loadingStandings && <ComponentFactory.Loading />}
                {!loadingStandings && this.standingsContent()}
            </>
        )
    }

    render() {
        return(
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}