import * as React from "react";
import {Observer, observer} from "mobx-react";
import * as _ from "lodash";
import * as ComponentFactory from "../../components/ComponentFactory";
import {LeaguesProps, LeaguesState, LeagueTabKey, LeagueTabOptions} from "./LeaguesInterface";
import {BaseComponent} from "../../components/BaseComponent";
import {Layout, Drawer, Icon, Collapse} from "antd";
import {Link} from "react-router-dom";
import {AppMode} from "../../stores/AppStore";

const {Sider} = Layout;

@observer
export class Leagues extends BaseComponent<LeaguesProps, LeaguesState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadingLeagues: true,
            currentLeague: undefined,
            activeSubMenu: "0",
            drawerVisible: false,
        };

        this.openCurrentSubmenu = this.openCurrentSubmenu.bind(this);
        this.handleLeagueChange = this.handleLeagueChange.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    componentDidMount() {
        const {dataStore} = this;
        const {shouldFetchLeagues, leagues} = dataStore;

        if (shouldFetchLeagues && leagues.length > 0) {
            this.setState({
                loadingLeagues: false,
                currentLeague: this.dataStore.selectedLeague,
            });
        }
    }

    componentDidUpdate() {
        const {dataStore, state, props} = this;

        const activeTab = props.match.url === "/" || props.match.url.includes(LeagueTabOptions.Standings) ? LeagueTabKey.Standings : (props.match.url.includes(LeagueTabOptions.Results) ? LeagueTabKey.Results : LeagueTabKey.SignUp);
        if (state.activeSubMenu !== activeTab || dataStore.selectedLeague !== state.currentLeague) this.setState({
            activeSubMenu: activeTab,
            currentLeague: dataStore.selectedLeague
        });
    }

    toggleDrawer() {
        this.setState({drawerVisible: !this.state.drawerVisible})
    }


    handleLeagueChange(league: any) {
        const {dataStore}=this;

        if (dataStore.currentLeague.id !== league.id) {
            dataStore.setSelectedLeague(league.id);
        }
    }


    openCurrentSubmenu() {
        const {dataStore, state, props} = this;
        const {leagues} = dataStore;

        const leagueId = () => {
            let leagueId = props.match.params.id;

            if (!_.isNil(leagueId)) {
                dataStore.setSelectedLeague(parseInt(leagueId));
            } else {
                this.appStore.navigateTo(`/leagues/${state.currentLeague.id}/standings`);
            }
            return !_.isNil(leagueId) ? parseInt(leagueId) : state.currentLeague.id;
        }

        const leagueIndex = _.findIndex(leagues, (league: any) => {
            return league.id === leagueId();
        });

        return `${leagueIndex}`;
    }

    leagueContent() {
        const {dataStore, state} = this;
        const {leagues} = dataStore;
        const isMobile = this.appStore.state.mode === AppMode.Mobile;

        return (
            <>
                {!isMobile && (
                <Layout>
                    <Sider
                        className={"leagues-sider"}>
                            <Collapse
                                className={`leagues-sider-menu`}
                                defaultActiveKey={[this.openCurrentSubmenu()]}
                                accordion={true}
                                expandIconPosition={"right"}>
                                {leagues.map((league, index) => {
                                    return (
                                        <Collapse.Panel
                                            key={`${index}`}
                                            header={league.name}>
                                            <Link to={`/leagues/${league.id}/standings`} onClick={() => {
                                                this.handleLeagueChange(league);
                                                this.toggleDrawer();
                                            }}>
                                                <div className={`leagues-sider-menu-item ${this.state.currentLeague.id === league.id && this.state.activeSubMenu === LeagueTabKey.Standings ? "active" : ""}`}>
                                                    Standings
                                                </div>
                                            </Link>
                                            <Link to={`/leagues/${league.id}/match-results`} onClick={() => {
                                                this.handleLeagueChange(league);
                                                this.toggleDrawer();
                                            }}>
                                                <div className={`leagues-sider-menu-item ${this.state.currentLeague.id === league.id && this.state.activeSubMenu === LeagueTabKey.Results ? "active" : ""}`}>
                                                    Match Results
                                                </div>
                                            </Link>
                                            {dataStore.currentLeague.id === league.id &&
                                            <Link to={`/leagues/${league.id}/signup`} onClick={() => {this.toggleDrawer();}}>
                                                <div className={`leagues-sider-menu-item ${this.state.currentLeague.id === league.id && this.state.activeSubMenu === LeagueTabKey.SignUp ? "active" : ""}`}>
                                                    Sign Up
                                                </div>
                                            </Link>}
                                        </Collapse.Panel>
                                    )
                                })}
                            </Collapse>
                    </Sider>
                    {state.activeSubMenu === LeagueTabKey.SignUp ? <ComponentFactory.SignUp league={state.currentLeague}/> : (state.activeSubMenu === LeagueTabKey.Standings ? <ComponentFactory.Standings league={state.currentLeague}/> : <ComponentFactory.MatchResults league={state.currentLeague}/>)}
                </Layout>
                )}
                {isMobile && (
                    <>
                        <Drawer
                        title={<Icon className={"leagues-drawer-back"} type={"menu-fold"} onClick={() => this.toggleDrawer()} />}
                        className={"leagues-drawer"}
                        placement={"left"}
                        closable={false}
                        onClose={() => this.toggleDrawer()}
                        visible={this.state.drawerVisible}>
                            <Collapse
                                    className={`leagues-sider-menu mobile`}
                                    defaultActiveKey={[this.openCurrentSubmenu()]}
                                    accordion={true}
                                    expandIconPosition={"right"}>
                                    {leagues.map((league, index) => {
                                        return (
                                            <Collapse.Panel
                                                key={`${index}`}
                                                header={league.name}
                                                className={"leagues-sider-menu-container"}>
                                                <Link to={`/leagues/${league.id}/standings`} onClick={() => {
                                                    this.handleLeagueChange(league);
                                                    this.toggleDrawer();
                                                }}>
                                                    <div className={`leagues-sider-menu-item ${this.state.currentLeague.id === league.id && this.state.activeSubMenu === LeagueTabKey.Standings ? "active" : ""} mobile`}>
                                                        Standings
                                                    </div>
                                                </Link>
                                                <Link to={`/leagues/${league.id}/match-results`} onClick={() => {
                                                    this.handleLeagueChange(league);
                                                    this.toggleDrawer();
                                                }}>
                                                    <div className={`leagues-sider-menu-item ${this.state.currentLeague.id === league.id && this.state.activeSubMenu === LeagueTabKey.Results ? "active" : ""} mobile`}>
                                                        Match Results
                                                    </div>
                                                </Link>
                                                {dataStore.currentLeague.id === league.id &&
                                                <Link to={`/leagues/${league.id}/signup`} onClick={() => {this.toggleDrawer();}}>
                                                    <div className={`leagues-sider-menu-item ${this.state.currentLeague.id === league.id && this.state.activeSubMenu === LeagueTabKey.SignUp ? "active" : ""} mobile`}>
                                                        Sign Up
                                                    </div>
                                                </Link>}
                                            </Collapse.Panel>
                                        )
                                    })}
                                </Collapse>
                        </Drawer>
                        <Icon className={"leagues-drawer-handle"} type={"menu-unfold"} onClick={() => this.toggleDrawer()}/>
                        {state.activeSubMenu === LeagueTabKey.SignUp ? <ComponentFactory.SignUp league={state.currentLeague}/> : (state.activeSubMenu === LeagueTabKey.Standings ? <ComponentFactory.Standings league={state.currentLeague}/> : <ComponentFactory.MatchResults league={state.currentLeague}/>)}
                    </>
                    )}
            </>
        )
    };

    protected renderContent() {
        const content = (
            <>
                {this.state.loadingLeagues &&
                <ComponentFactory.Loading />}

                {!this.state.loadingLeagues &&
                    this.leagueContent()}
            </>
        );

        return (
            <>
                {content}
            </>
        );
    }


    render() {

        return (
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}