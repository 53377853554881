import axios, {AxiosInstance, AxiosRequestConfig, CancelTokenSource} from "axios";
import {AppConfig} from "../../AppConfig";

export class BaseAPIClient {

    static createClient(): {client: AxiosInstance, config: AxiosRequestConfig, cancelToken: CancelTokenSource } {
        const result: any = {};

        const clientConfig: AxiosRequestConfig = {
            timeout: AppConfig.Settings.Server.timeout,
            baseURL: AppConfig.Settings.Server.baseUrl
        };

        result.client = axios.create(clientConfig);

        const cancelTokenHelper = axios.CancelToken;
        result.cancelToken = cancelTokenHelper.source();

        const config: AxiosRequestConfig = {};
        config.headers = {};

        config.headers["Accept"] = "application/json";
        config.headers["Content-Type"] = "application/json";
        // if (!_.isNil(this.accessToken))  config.headers["Authorization"] = `token=${this.accessToken}`;

        config.params = {};

        config.cancelToken = result.cancelToken.token;

        config.withCredentials = false;

        result.config = config;

        return result;
    }

    static processResponse(response: any)  {
        if (response.status >= 300) {
            response.success = false;
        } else {
            response.success = true;
        }

        return response;
    }
}