import * as React from "react";
import * as _ from "lodash";
import {Observer} from "mobx-react";
import {MatchResultProps, MatchResultState} from "./MatchResultItemInterface";
import {BaseComponent} from "../BaseComponent";
import moment from "moment";
import {AppMode} from "../../stores/AppStore";

export class MatchResultItem extends BaseComponent<MatchResultProps, MatchResultState> {
    constructor(props: any) {
        super(props);
        this.state = {};

        this.matchResultContent = this.matchResultContent.bind(this);
    }

    componentDidMount() {
    }

    matchResultContent() {
        const {props} = this;
        const {matchResult} = props;
        const isMobile = this.appStore.state.mode === AppMode.Mobile;
        return (
            <div className={`match-result-item-container ${isMobile ? "mobile" : ""}`}>
                <div className={`match-result-item-date ${isMobile ? "mobile" : ""}`}>
                    {moment(matchResult.created_at).format('ll')}
                </div>
                <div className={`match-result-item ${isMobile ? "mobile" : ""}`}>
                    <div className={`match-result-item-title ${isMobile ? "mobile" : ""}`}>
                        Winner
                    </div>
                    <div className={`match-result-item-content ${isMobile ? "mobile" : ""}`}>
                        {!_.isNil(matchResult.winner) && !_.isNil(matchResult.winner!.first_name) ? matchResult.winner!.first_name : ""} {!_.isNil(matchResult.winner) && !_.isNil(matchResult.winner!.last_name) ? matchResult.winner!.last_name : ""}
                    </div>
                </div>
                <div className={`match-result-item ${isMobile ? "mobile" : ""}`}>
                    <div className={`match-result-item-title ${isMobile ? "mobile" : ""}`}>
                        Loser
                    </div>
                    <div className={`match-result-item-content ${isMobile ? "mobile" : ""}`}>
                        {!_.isNil(matchResult.loser) && !_.isNil(matchResult.loser!.first_name) ? matchResult.loser!.first_name : ""} {!_.isNil(matchResult.loser) && !_.isNil(matchResult.loser!.last_name) ? matchResult.loser!.last_name : ""}
                    </div>
                </div>
                <div className={`match-result-item ${isMobile ? "mobile" : ""}`}>
                    <div className={`match-result-item-title ${isMobile ? "mobile" : ""}`}>
                        Games Played
                    </div>
                    <div className={`match-result-item-content ${isMobile ? "mobile" : ""}`}>
                        {matchResult.games_count}
                    </div>
                </div>
            </div>
        )
    }

    protected renderContent() {
        return this.matchResultContent()
    }

    render() {
        return(
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}