import * as React from "react";
import {Observer, observer} from "mobx-react";
import * as _ from "lodash";
import * as ComponentFactory from "../../components/ComponentFactory";
import {OverallStandingsProps, OverallStandingsState} from "./OverallStandingsInterface";
import {BaseComponent} from "../../components/BaseComponent";
import * as Models from "../../../core/models/Index";
import {Icon, Input, Table, Tag} from "antd";
import {AppMode} from "../../stores/AppStore";


@observer
export class OverallStandings extends BaseComponent<OverallStandingsProps, OverallStandingsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadingOverallStandings: false,
            searchInputValue: undefined,
        };

        this.overallStandingsContent = this.overallStandingsContent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.searchResults = this.searchResults.bind(this);
    }

    componentDidMount() {
        const {dataStore, state} = this;
        const {shouldFetchOverallStandings} = dataStore;
        if (!state.loadingOverallStandings && shouldFetchOverallStandings) {
            this.setState({
                loadingOverallStandings: true,
            }, () => {
                dataStore.getOverallStandings()
                    .then((res) => {
                        if (!res.success) {
                            this.appStore.showMessage("error", res.statusText);
                        }
                        this.setState({
                            loadingOverallStandings: false,
                        });
                    })
                    .catch(err => console.log(err));
            });
        }
    }

    handleSearchChange(event: any) {
        this.setState({
            searchInputValue: event.target.value,
        })
    }

    searchResults(standings: any) {
        const {state} = this;

        return  standings!.filter( (standing: any) => {
            if (standing) {
                const search = !_.isNil(state.searchInputValue) ? state.searchInputValue.toLowerCase() : "";
                if (standing.first_name!.toLowerCase().includes(search) ||
                    standing.last_name!.toLowerCase().includes(search)) {
                    return standing;
                }
            }
        })
    }

    leagueStandingsContent(records: Models.LeagueRecord[]) {
        return (
            <>
                <p className={"overall-standings-league-records-title"}>League Records</p>
                <div className={"overall-standings-league-records-container"}>
                    {records.map((record: any) => (<Tag color={"#001529"} className={"overall-standings-league-record"}>{record.league_abbreviation}: {record.record}</Tag>))}
                </div>
            </>
        );
    }


    overallStandingsContent() {
        const {dataStore, state} = this;
        const {overallStandings} = dataStore;
        const isMobile = this.appStore.state.mode === AppMode.Mobile;
        const results = _.isNil(state.searchInputValue) ? overallStandings : this.searchResults(overallStandings);

        const columns = [
          {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a: any, b: any) => a.first_name.localeCompare(b.first_name)
          },
          {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
            sorter: (a: any, b: any) => a.last_name.localeCompare(b.last_name)
          },
          {
            title: 'Overall Wins',
            dataIndex: 'overall_wins',
            key: 'overall_wins',
            sorter: (a: any, b: any) => a.overall_wins - b.overall_wins
          },
          {
            title: 'Overall Losses',
            dataIndex: 'overall_losses',
            key: 'overall_losses',
            sorter: (a: any, b: any) => a.overall_losses - b.overall_losses
          },
          {
            title: 'Overall Points',
            dataIndex: 'overall_points',
            key: 'overall_points',
            sorter: (a: any, b: any) => a.overall_points - b.overall_points
          },
          {
            title: 'Overall Record',
            dataIndex: 'overall_record',
            key: 'overall_record'
          },
          {
            title: 'Total Matches',
            dataIndex: 'total_matches',
            key: 'total_matches',
            sorter: (a: any, b: any) => a.total_matches - b.total_matches
          },
          {
            title: 'Win Percentage',
            dataIndex: 'win_percentage',
            key: 'win_percentage',
            sorter: (a: any, b: any) => a.win_percentage - b.win_percentage,
            render: (percentage: number) => (`${percentage}%`)
          },
          {
            title: 'Pro',
            dataIndex: 'pro',
            key: 'pro',
            sorter: (a: any, b: any) => a.pro - b.pro,
            render: (pro: any) => (pro ? <Tag color={'#1890ff'} key={pro}>Pro</Tag> : "")
          },
          {
            title: 'Elo',
            dataIndex: 'elo',
            key: 'elo',
            sorter: (a: any, b: any) => a.elo - b.elo
          }
        ];

        return (
            <>
                <div className={`overall-standings-search-container ${isMobile ? "mobile" : ""}`}>
                    <Input className={`overall-standings-results-input ${isMobile ? "mobile" : ""}`}
                           placeholder={"Search Overall Standings"}
                           value={this.state.searchInputValue}
                           onChange={(e) => {this.handleSearchChange(e)}}
                           suffix={_.isNil(this.state.searchInputValue) ? <Icon type={"search"} /> : <Icon type={"close"} className={"overall-standings-input-clear"} onClick={() => this.setState({searchInputValue: undefined})} />}
                    />
                </div>
                <div className={`overall-standings-wrapper ${isMobile ? "mobile" : ""}`}>
                    {!_.isNil(results) && results.length > 0 ?
                        <Table
                            className={"overall-standings-table-container"}
                            dataSource={results}
                            columns={columns}
                            expandedRowRender={(record: any) => this.leagueStandingsContent(record.individual_league_records)}
                        />
                        : <p>No results.</p>}
                </div>
            </>
        );
    };

    protected renderContent() {
        const content = (
            <>
                {this.state.loadingOverallStandings &&
                <ComponentFactory.Loading />}

                {!this.state.loadingOverallStandings &&
                    this.overallStandingsContent()}
            </>
        );

        return (
            <>
                <div className={"overall-standings-main-container"}>{content}</div>
            </>
        );
    }


    render() {

        return (
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}