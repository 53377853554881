import * as React from "react";

import * as _ from "lodash";
import {Observer} from "mobx-react";
import {ReportMatchProps, ReportMatchState} from "./ReportMatchInterface";
import {BaseComponent} from "../../components/BaseComponent";
import {Loading} from "../../components/ComponentFactory";

import {Select, Button} from "antd";

const {Option} = Select;

export class ReportMatch extends BaseComponent<ReportMatchProps, ReportMatchState> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentLeague: undefined,
            loadingLeaguePlayers: false,
            winner_id: undefined,
            loser_id: undefined,
            games_count: undefined,
            league_id: this.dataStore.currentLeague.id,
        }

        this.loadLeague = this.loadLeague.bind(this);
;       this.renderUsers = this.renderUsers.bind(this);
        this.renderLeagues = this.renderLeagues.bind(this);
        this.winnerOptions = this.winnerOptions.bind(this);
        this.loserOptions = this.loserOptions.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.gameCount = this.gameCount.bind(this);
        this.leagueOptions = this.leagueOptions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        this.loadLeague(this.dataStore.currentLeague.id);
    }

    componentDidUpdate() {
        if (this.state.league_id !== this.state.currentLeague) this.setState({
            currentLeague: this.state.league_id,
            loser_id: undefined,
            winner_id: undefined
        }, () =>  this.loadLeague(this.state.currentLeague))
    }

    loadLeague(leagueID: any) {
        const {dataStore, state} = this;
        if (!state.loadingLeaguePlayers && dataStore.shouldFetchLeagueDetails && !_.isNil(dataStore.currentLeague)) {
            this.setState({
                currentLeague: leagueID,
                loadingLeaguePlayers: true,
            }, () => {
                dataStore.getLeagueByID(leagueID).then(res => {
                    if (res.success) {
                        this.setState({
                            loadingLeaguePlayers: false,
                        });
                    }
                })
            })
        }
    }



    handleSelectChange(value: any, input: string) {
        if (input === 'winner') this.setState({winner_id: value});
        else if (input === 'loser')this.setState({loser_id: value});
        else if (input === 'games')this.setState({games_count: value});
        else this.setState({league_id: value});
    }

    renderLeagues() {
        const {dataStore} = this;

        return dataStore.leagues!.map((league) => {
            return <Option value={league.id} key={league.id}>{league.name}</Option>
        });
    }

    renderUsers() {
        const {dataStore, state} = this;

        return dataStore.reportMatchLeagueDetails!.users!.map((user) => {
            const name = `${user.first_name} ${user.last_name}`;

            return state.winner_id !== user.id && state.loser_id !== user.id && <Option value={user.id} key={user.id}>{name}</Option>
        });
    }

    loserOptions() {
        const {dataStore} = this;
        return (
            <>
                <span className={"report-match-form-label"}>Loser: </span>
                <Select showSearch={true}
                        placeholder={"Search for Loser"}
                        onChange={(value: any) => this.handleSelectChange(value, 'loser')}
                        optionFilterProp="children"
                        filterOption={true}
                        className={"report-match-input"}
                >
                    {!_.isNil(dataStore!.reportMatchLeagueDetails!.users) && this.renderUsers()}
                </Select>
            </>
        )
    }

    winnerOptions() {
        const {dataStore} = this;
        return (
            <>
                <span className={"report-match-form-label"}>Winner: </span>
                <Select showSearch={true}
                        placeholder={"Search for Winner"}
                        onChange={(value: any) => this.handleSelectChange(value, 'winner')}
                        optionFilterProp="children"
                        filterOption={true}
                        className={"report-match-input"}
                >
                    {!_.isNil(dataStore!.reportMatchLeagueDetails!.users) && this.renderUsers()}
                </Select>
            </>
        )
    }
    
    gameCount() {
        return(
            <>
                <span className={"report-match-form-label"}>Games Played: </span>
                <Select placeholder={"Number of Games Played"}
                        onChange={(value) => this.handleSelectChange(value, 'games')}
                        className={"report-match-input"}
                        allowClear={true}
                >
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                </Select>
            </>
        )
    }

    leagueOptions() {
        const {state} = this;
        const {currentLeague} = state;
        return(
            <>
                <span className={"report-match-form-label"}>League: </span>
                <Select onChange={(value: any) => this.handleSelectChange(value, 'league')}
                        className={"report-match-input"}
                        defaultValue={currentLeague}
                >
                    {this.renderLeagues()}
                </Select>
            </>

        )
    }

    validateForm() {
        const {state} = this;
        if (_.isNil(state.winner_id) || _.isNil(state.loser_id) || _.isNil(state.games_count) || _.isNil(state.league_id)) {
            this.appStore.showMessage('error', 'Please provide all information.');
            return;
        }
        else return {
            match_result: {
                user_id: 1,
                winner_id: state.winner_id,
                loser_id: state.loser_id,
                games_count: state.games_count,
                league_id: state.league_id
            }
        }
    }

    handleSubmit() {
        const {dataStore, appStore} = this;
        const match = this.validateForm();
        if (!_.isNil(match)) dataStore.submitMatch(match)
            .then(res => {
                if (res.success) {
                    appStore.navigateTo(`/leagues/${res.data.league_id}/match-results`);
                } else {
                    this.appStore.showMessage("error", res.statusText);
                }
            })
            .catch(err => {
                console.log(err);
                appStore.showMessage('error', err);
            })
    }

    protected renderContent() {
        const {dataStore, state} = this;
        return (
            <>
                {_.isNil(state.currentLeague) && state.loadingLeaguePlayers && <Loading />}
                {!_.isNil(state.currentLeague) && !state.loadingLeaguePlayers && (
                <div className={"report-match-main-container"}>
                    <div className={"report-match-form-container"}>
                        <h2> Submit Match </h2>
                        <div className={"report-match-form-row"}>
                            {this.leagueOptions()}
                        </div>
                        <div className={"report-match-form-row"}>
                            {this.winnerOptions()}
                        </div>
                        <div className={"report-match-form-row"}>
                            {this.loserOptions()}
                        </div>
                        <div className={"report-match-form-row"}>
                            {this.gameCount()}
                        </div>
                        <div className={"report-match-form-submit"}>
                            <Button onClick={() => this.handleSubmit()} disabled={state.loadingLeaguePlayers || _.isNil(dataStore.reportMatchLeagueDetails)}>
                                Submit Results
                            </Button>
                        </div>
                    </div>
                </div>
                )}
            </>
        )
    }

    render() {
        return(
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}