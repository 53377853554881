export enum LeagueTabOptions {
    Standings = "standings",
    Results = "results",
}

export enum LeagueTabKey {
    Standings = "0",
    Results = "1",
    SignUp = "2",
}

export interface LeaguesProps {
    match: any
}

export interface LeaguesState {
    loadingLeagues?: boolean;
    activeSubMenu?: string;
    currentLeague: any | undefined;
    drawerVisible: boolean;
}