import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import * as React from "react";
import {Observer, Provider, observer} from "mobx-react";
import * as ComponentFactory from "./components/ComponentFactory";
import {DataStore} from "../core/stores/index";
import {AppStore, AppMode} from "./stores/AppStore";
import moment from "moment";
import { Layout, Menu, Dropdown, Icon } from "antd";
import Logo from "../assets/header/mtg-logo.svg";

const { Content, Footer, Header } = Layout;

enum NavigationKeys {
    Leagues = "0",
    Report = "1",
    Profile = "2",
    Faq = "3",
    OverallStandings = "4"
}

enum NavigationOptions {
    Leagues = "leagues",
    Report = "report-match",
    Profile = "profile",
    Faq = "faq",
    OverallStandings = "overall-standings"
}

export interface AppProps {
    config?: any;
    locale?: string;
    showHeader?: boolean;
    match?: any;
}

export interface AppState {
    isLoading?: boolean;
    mode?: string;
    currentPage?: any;
}

@observer
class App extends React.Component<AppProps, AppState> {
    state: AppState = {} as AppState;
    appStore: AppStore;
    dataStore: DataStore;
    constructor(props: AppProps) {
        super(props);
        this.state = {
            isLoading: true,
            mode: AppMode.Desktop,
            currentPage: '/',
        };
        this.appStore = new AppStore();
        this.dataStore = new DataStore();
        this.handleResize = this.handleResize.bind(this);
        this.getCurrentNavItem = this.getCurrentNavItem.bind(this);
    }

    componentWillMount() {
        const {dataStore} = this;
        const {isInitializing, isInitialized} = dataStore;

        if (!isInitializing && !isInitialized) {
            this.appStore.dataStore.initialize()
                .then(res => {
                    if (res.success) {
                        this.setState({isLoading: false});
                    } else {
                        this.appStore.showMessage('error', res.statusText);
                    }
                });
        }
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        const mode = this.appStore.evaluateMode();
        if (this.state.mode !== mode)
            this.setState({mode: mode});
    }

    getCurrentNavItem() {
        const {pathname} = AppStore.location;

        if (pathname.includes(NavigationOptions.Report)) return NavigationKeys.Report
        else if (pathname.includes(NavigationOptions.Profile)) return NavigationKeys.Profile
        else if (pathname.includes(NavigationOptions.OverallStandings)) return NavigationKeys.OverallStandings
        else return NavigationKeys.Leagues;
    }

    render() {
        const mobile = this.state.mode === AppMode.Mobile;
        const mobileMenu = (
            <Menu
                    theme="light"
                    mode="vertical"
                    selectedKeys={[this.state.currentPage]}
                    style={{ lineHeight: '64px' }}
                >
                <Menu.Item key={NavigationKeys.Leagues} onClick={() => {
                    this.appStore.navigateTo("/");
                }}>Leagues</Menu.Item>
                <Menu.Item key={NavigationKeys.OverallStandings} onClick={() => {
                    this.appStore.navigateTo("/overall-standings");
                }}> Overall Standings</Menu.Item>
                <Menu.Item key={NavigationKeys.Report} onClick={() => {
                    this.appStore.navigateTo("/report-match");
                }}> Report A Match</Menu.Item>
                <Menu.Item key={NavigationKeys.Faq} onClick={() => {
                    this.appStore.openInNewTab("https://agbaber.github.io/mtg_league_faq/");
                }}>FAQ</Menu.Item>
            </Menu>
        );
        const header = (
            <>
                {!mobile && (
                    <Header className={"app-header"}>
                        <Link to={"/"}>
                            <div className={"app-logo"} style={{backgroundImage: `url(${Logo}`}}></div>
                        </Link>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            selectedKeys={[this.state.currentPage]}
                            style={{ lineHeight: '64px' }}
                        >
                            <Menu.Item key={NavigationKeys.Leagues} onClick={() => this.appStore.navigateTo("/")}>Leagues</Menu.Item>
                            <Menu.Item key={NavigationKeys.OverallStandings} onClick={() => { this.appStore.navigateTo("/overall-standings");}}> Overall Standings</Menu.Item>
                            <Menu.Item key={NavigationKeys.Report} onClick={() => this.appStore.navigateTo("/report-match")}> Report A Match</Menu.Item>
                            <Menu.Item key={NavigationKeys.Faq} onClick={() => this.appStore.openInNewTab("https://agbaber.github.io/mtg_league_faq/")}>FAQ</Menu.Item>
                        </Menu>
                    </Header>
                )}
                {mobile && (
                    <Header className={"app-header mobile"}>
                        <div className={"app-logo mobile"} style={{backgroundImage: `url(${Logo}`}}></div>
                        <Dropdown overlay={mobileMenu} trigger={['click']}>
                            <Icon className={"app-header-menu"} type={"menu"}/>
                        </Dropdown>
                    </Header>
                )}
            </>
        );

        const content = (
            <>
                <Content className={"app-main-wrapper"}>
                    <Switch>
                        <Route exact path={"/"} render={(props: any) => <ComponentFactory.Leagues {...props}/>}/>
                        <Route exact path={"/leagues/:id/standings"} render={(props: any) => <ComponentFactory.Leagues {...props}/>}/>
                        <Route exact path={"/leagues/:id/match-results"} render={(props: any) => <ComponentFactory.Leagues {...props}/>}/>
                        <Route exact path={"/leagues/:id/signup"} render={(props: any) => <ComponentFactory.Leagues {...props}/>}/>
                        <Route exact path={"/report-match"} render={(props: any) => <ComponentFactory.ReportMatch {...props}/>}/>
                        <Route exact path={"/overall-standings"} render={(props: any) => <ComponentFactory.OverallStandings {...props}/>}/>
                        <Route render={(props: any) => <ComponentFactory.NotFound {...props}/>}/>
                    </Switch>
                </Content>
            </>
        );

        const layout = (
            <div className={"app-main-container"}>
                <Layout>
                    {this.state.isLoading &&
                    <ComponentFactory.Loading />}

                    {!this.state.isLoading &&
                        <>
                            <Layout>
                                {header}
                                {content}
                                <Footer className={"app-footer"}>
                                    <p>MTG League &copy; {moment().format('YYYY')}</p>
                                    <p>Magic: The Gathering is ™ and © {moment().format('YYYY')} Wizards of the Coast</p>
                                    <p>mtg-league.com is unaffiliated with Wizards of the Coast</p>
                                    <p>Not approved/endorsed by Wizards. Portions of the materials used are property of Wizards of the Coast. ©Wizards of the Coast LLC.</p>
                                </Footer>
                            </Layout>
                        </>
                    }
                </Layout>
            </div>
        );

        return (
            <Provider>
                <>
                <Observer>
                    {() => 
                    <Router>
                        <Route path="/"
                            render={(props: any) => {
                                AppStore.history = props.history;
                                AppStore.location = props.location;

                                if (this.state.currentPage !== this.getCurrentNavItem()) this.setState({currentPage: this.getCurrentNavItem()});
                                return layout;
                            }}/>
                    </Router>}
                </Observer>
                </>
            </Provider>
        );
    }
}

export default App;