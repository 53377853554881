import * as React from "react";
import {Observer} from "mobx-react";
import {StandingProps, StandingState} from "./StandingItemInterface";
import {BaseComponent} from "../BaseComponent";

import {Avatar, Popover, Button, Icon} from "antd";
import {AppMode} from "../../stores/AppStore";

export class StandingItem extends BaseComponent<StandingProps, StandingState> {
    constructor(props: any) {
        super(props);
        this.state = {};

        this.standingContent = this.standingContent.bind(this);
    }

    componentDidMount() {
    }

    standingContent() {
        const {props} = this;
        const {standing} = props;
        const isMobile = this.appStore.state.mode === AppMode.Mobile;

        const popoverContent = (
            <>
                <h5 className={"standing-prize-title"}>Weekly / Overall Prize Packs: <span>{standing.weekly_and_overall_prizes}</span></h5>
                <h5 className={"standing-prize-title"}>Placement Prize Packs: <span>{standing.placement_prizes}</span></h5>
                <h5 className={"standing-prize-title total"}>Total Prize Packs: <span>{standing.total_prizes}</span></h5>
            </>
        )
        return (
            <div className={`standing-item-container ${isMobile ? "mobile" : ""}`}>
                <div className={`standing-item-content ${isMobile ? "mobile" : ""}`}>
                    <Avatar className={`standing-item-avatar ${isMobile ? "mobile" : ""}`}>
                        {standing.ranking}
                    </Avatar>
                    <div className={`standing-item-name ${isMobile ? "mobile" : ""}`}>{standing.first_name} {standing.last_name}</div>
                    <div className={`standing-item-office ${isMobile ? "mobile" : ""}`}>
                        <Popover className={"standing-popover"}title={"Prize Pack Breakdown"} trigger="click" content={popoverContent}>
                            <Button className={"standing-popover-button"} type="primary">
                                {standing.office}
                                <Icon type="trophy" />
                                <div className={`standing-badge ${isMobile ? "mobile" : ""}`}>{standing.total_prizes}</div>
                            </Button>
                        </Popover>
                    </div>
                </div>
                <div className={`standing-item-record ${isMobile ? "mobile" : ""}`}>{standing.overall_record}</div>
                <div className={`standing-results-container ${isMobile ? "mobile" : ""}`}>
                    <div className={`standing-results-content ${isMobile ? "mobile" : ""}`}>
                        <div className={`standing-results-title ${isMobile ? "mobile" : ""}`}>Week 1</div>
                        <div className={`standing-results-item ${isMobile ? "mobile" : ""}`}>{standing.week1_record}</div>
                    </div>
                    <div className={`standing-results-content ${isMobile ? "mobile" : ""}`}>
                        <div className={`standing-results-title ${isMobile ? "mobile" : ""}`}>Week 2</div>
                        <div className={`standing-results-item ${isMobile ? "mobile" : ""}`}>{standing.week2_record}</div>
                    </div>
                    <div className={`standing-results-content ${isMobile ? "mobile" : ""}`}>
                        <div className={`standing-results-title ${isMobile ? "mobile" : ""}`}>Week 3</div>
                        <div className={`standing-results-item ${isMobile ? "mobile" : ""}`}>{standing.week3_record}</div>
                    </div>
                    <div className={`standing-results-content ${isMobile ? "mobile" : ""}`}>
                        <div className={`standing-results-title ${isMobile ? "mobile" : ""}`}>Week 4</div>
                        <div className={`standing-results-item ${isMobile ? "mobile" : ""}`}>{standing.week4_record}</div>
                    </div>
                </div>
            </div>
        )
    }

    protected renderContent() {
        return this.standingContent()
    }

    render() {
        return(
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}