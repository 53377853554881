import * as React from "react";
import {Observer, observer} from "mobx-react";
import * as _ from "lodash";
import * as ComponentFactory from "../../components/ComponentFactory";
import {SignUpProps, SignUpState} from "./SignUpInterface";
import {BaseComponent} from "../BaseComponent";

import {Tabs, Input, Select, Button} from "antd";
import {AppMode} from "../../stores/AppStore";

const {TabPane} = Tabs;
const {Option} = Select;

@observer
export class SignUp extends BaseComponent<SignUpProps, SignUpState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadingSignUpData: false,
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            experience_level: undefined,
            judge_level: undefined,
            office: undefined,
            signUpData: undefined,
            user_id: undefined,
            dci_number: undefined,
            isError: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitSignUp = this.submitSignUp.bind(this);
    }

    componentDidMount() {
        const {state, dataStore} = this;
        if (!state.loadingSignUpData && dataStore.shouldFetchSignUpData) {
            this.setState({loadingSignUpData: true}, () => {
                dataStore.getSignUpData()
                    .then(res => {
                        if (res.success) {
                            this.setState({
                                loadingSignUpData: false,
                                signUpData: res.data,
                            });
                        } else {
                            this.appStore.showMessage("error", res.statusText);
                        }
                    })
                    .catch(err => console.log(err));
            });
        }
    }

    handleChange(value: any, input: any) {
        let item = {}
        item[input] = value;
        this.setState(item);
    };

    validateForm(value: string) {
        const {state, dataStore} = this;
        if (value === 'new') {
            if (!_.isNil(state.first_name) && !_.isNil(state.last_name) && !_.isNil(state.email) && !_.isNil(state.office)) {
                return {
                    signups: {
                        email: state.email,
                        first_name: state.first_name,
                        last_name: state.last_name,
                        office: state.office,
                        experience_level: !_.isNil(state.experience_level) ? state.experience_level : null,
                        judge_tier: !_.isNil(state.judge_level) ? state.judge_level : null,
                        league_id: dataStore.currentLeague.id,
                        dci_number: !_.isNil(state.dci_number) ? state.dci_number : null,
                    }
                }
            } else {
                this.setState({isError: true});
                return undefined;
            }
        } else {
            if (!_.isNil(state.user_id)) {
                return {
                    signups: {
                        user_id: state.user_id,
                        league_id: dataStore.currentLeague.id,
                    }
                }
            } else {
                this.setState({isError: true});
                return undefined;
            }
        }
    }

    submitSignUp(obj: any) {
        const {dataStore, appStore} = this;
        dataStore.submitSignUp(obj)
            .then((res) => {
                if (res.success) {
                    debugger;
                    appStore.navigateTo("/");
                    appStore.showMessage("success", `Successfully Registered for ${dataStore.currentLeague.name}`);
                } else {
                    appStore.showMessage("error", res.statusText);
                }
            })
            .catch(err => console.log(err));
    };

    handleSubmit(value: string) {
        const {appStore} = this;
        this.setState({isError: false}, () => {
            if (value === 'new') {
                const newUser = this.validateForm('new');
                if (!_.isNil(newUser)) {
                    this.submitSignUp(newUser);
                } else {
                    appStore.showMessage("error", 'Please complete all required fields');
                }
            } else {
                const existingUser = this.validateForm('existing');
                if (!_.isNil(existingUser)) {
                    this.submitSignUp(existingUser);
                } else {
                    appStore.showMessage("error", 'Please complete all required fields');
                }
            }
        });
    }

    protected renderContent() {
        const {state, dataStore} = this;
        const isMobile = this.appStore.state.mode === AppMode.Mobile;
        const returnUserForm = (
            <div className={`signup-form-container ${isMobile ? "mobile" : ""}`}>
                <div className={`signup-form-item ${isMobile ? "mobile" : ""}`}>
                    <span className={`signup-form-span ${isMobile ? "mobile" : ""} ${this.state.isError && _.isNil(state.user_id) ? "error" : ""}`}>Players:</span>
                    <Select className={`${this.state.isError && _.isNil(state.user_id)? "error" : ""}`}
                            showSearch={true}
                            placeholder={"Find Player"}
                            optionFilterProp="children"
                            onChange={(value: any) => this.handleChange(value, "user_id")}
                            filterOption={true}>
                        {!_.isNil(state.signUpData) && state!.signUpData!.existing_users!.map((user: any, index: any) => {
                            return <Option key={index} value={user.id}>{`${user.first_name} ${user.last_name}`}</Option>
                        })}
                    </Select>
                </div>
                <Button className={"signup-submit"} onClick={() => this.handleSubmit('existing')}>Join League</Button>
            </div>
        );

        const newUserForm = (
            <div className={`signup-form-container ${isMobile ? "mobile" : ""}`}>
                <div className={`signup-form-item ${isMobile ? "mobile" : ""}`}>
                    <span className={`signup-form-span ${isMobile ? "mobile" : ""} ${this.state.isError && _.isNil(state.email) ? "error" : ""}`}>Email:</span>
                    <Input className={`${this.state.isError && _.isNil(state.email)  ? "error" : ""}`} type={"email"} placeholder={"Grubhub Email Address"} value={state.email} onChange={(value: any) => this.handleChange(value.target.value, "email")} />
                </div>
                <div className={`signup-form-item ${isMobile ? "mobile" : ""}`}>
                    <span className={`signup-form-span ${isMobile ? "mobile" : ""} ${this.state.isError && _.isNil(state.first_name) ? "error" : ""}`}>First Name:</span>
                    <Input className={`${this.state.isError && _.isNil(state.first_name) ? "error" : ""}`} type={"text"} placeholder={"First Name"} value={state.first_name} onChange={(value: any) => this.handleChange(value.target.value, "first_name")}/>
                </div>
                <div className={`signup-form-item ${isMobile ? "mobile" : ""}`}>
                    <span className={`signup-form-span ${isMobile ? "mobile" : ""} ${this.state.isError && _.isNil(state.last_name) ? "error" : ""}`}>Last Name:</span>
                    <Input className={`${this.state.isError && _.isNil(state.last_name) ? "error" : ""}`} type={"text"} placeholder={"Last Name"} value={state.last_name} onChange={(value: any) => this.handleChange(value.target.value, "last_name")}/>
                </div>
                <div className={`signup-form-item ${isMobile ? "mobile" : ""}`}>
                    <span className={`signup-form-span ${isMobile ? "mobile" : ""} ${this.state.isError && _.isNil(state.office) ? "error" : ""}`}>Office:</span>
                    <Select className={`${this.state.isError && _.isNil(state.office) ? "error" : ""}`} placeholder={"Select Office"} onChange={(value: any) => this.handleChange(value, "office")}>
                        {!_.isNil(state.signUpData) && state!.signUpData!.office!.map((office: any, index: any) => {
                            return <Option key={index} value={office}>{office}</Option>
                        })}
                    </Select>
                </div>
                <div className={`signup-form-item ${isMobile ? "mobile" : ""}`}>
                    <span className={`signup-form-span ${isMobile ? "mobile" : ""}`}>Exp Level:</span>
                    <Select placeholder={"Experience Level"} onChange={(value: any) => this.handleChange(value, "experience_level")}>
                        {!_.isNil(state.signUpData) && state!.signUpData!.experience_level!.map((level: any, index: any) => {
                            return <Option key={index} value={level}>{level}</Option>
                        })}
                    </Select>
                </div>
                <div className={`signup-form-item ${isMobile ? "mobile" : ""}`}>
                    <span className={`signup-form-span ${isMobile ? "mobile" : ""}`}>Judge Level:</span>
                    <Select placeholder={"Judge Level"} onChange={(value: any) => this.handleChange(value, "judge_level")}>
                        {!_.isNil(state.signUpData) && state!.signUpData!.judge_level!.map((level: any, index: any) => {
                            return <Option key={index} value={level}>{level}</Option>
                        })}
                    </Select>
                </div>
                <div className={`signup-form-item ${isMobile ? "mobile" : ""}`}>
                    <span className={`signup-form-span ${isMobile ? "mobile" : ""}`}>DCI Number*:</span>
                    <Input type={"text"} placeholder={"DCI Number"} value={state.dci_number} onChange={(value: any) => this.handleChange(value.target.value, "dci_number")}/>
                </div>
                <p>*If you do not provide a <a target="_blank" rel="noopener noreferrer" href="https://accounts.wizards.com">DCI Number</a>, one will be created for you.</p>

                <Button className={"signup-submit"} onClick={() => this.handleSubmit('new')}>Join League</Button>
            </div>
        );


        const content = (
            <>
                {state.loadingSignUpData && <ComponentFactory.Loading/>}
                {!state.loadingSignUpData && !_.isNil(dataStore!.signUpData) && <div className={`signup-main-container ${isMobile ? "mobile" : ""}`}>
                    <Tabs defaultActiveKey="1" type={"card"} className={"signup-tab"}>
                        <TabPane tab="Returning Player" key="1" >
                            {returnUserForm}
                        </TabPane>
                        <TabPane tab="New Player" key="2">
                            {newUserForm}
                        </TabPane>
                    </Tabs>
                </div>}
            </>
        );

        return (
            <>
                {content}
            </>
        );
    }


    render() {
        return (
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}