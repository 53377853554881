import * as _ from "lodash";
// import * as localforage from "localforage";
import {observable, action, runInAction} from "mobx";
import APIClient from "./apiClient/APIClient";

import * as Models from "../models/Index";

export class DataStore {
    private static instance: any = null;
    // private static storage: LocalForage;

    leaguesInitialState: Models.League[] = [];

    currentLeagueInitialState: Models.League = {
        id: undefined,
        name: undefined,
        active: undefined,
        set_abbreviation: undefined,
        created_at: undefined,
        updated_at: undefined,
    };

    selectedLeagueInitialState: Models.League = {
        id: undefined,
        name: undefined,
        active: undefined,
        set_abbreviation: undefined,
        created_at: undefined,
        updated_at: undefined
    };

    reportMatchLeagueDetailsInitialState: Models.League = {
        id: undefined,
        name: undefined,
        active: undefined,
        set_abbreviation: undefined,
        created_at: undefined,
        updated_at: undefined,
        users: undefined,
    };

    signUpDataInitialState: Models.SignUp = {
        email: undefined,
        existing_users: undefined,
        experience_level: undefined,
        judge_level: undefined,
        first_name: undefined,
        last_name: undefined,
        office: undefined,
        dci_number: undefined,
    }

    standingInitialState: Models.Standing[] | undefined = undefined;

    matchResultsInitialState: Models.MatchResult[] | undefined = undefined;

    overallStandingInitialState: Models.OverallStanding[] | undefined = undefined;

    shouldFetchStandings: boolean = true;

    shouldFetchMatchResults: boolean = true;

    shouldFetchLeagues: boolean = true;

    shouldFetchLeagueDetails: boolean = true;

    shouldFetchOverallStandings: boolean = true;

    shouldFetchSignUpData: boolean = true;

    @observable
    isInitialized: boolean = false;

    @observable
    isInitializing: boolean = false;

    @observable
    authorizedUser: any = undefined;

    @observable
    leagues: Models.League[] = _.cloneDeep<Models.League[]>(this.leaguesInitialState);

    @observable
    currentLeague: Models.League = _.cloneDeep<Models.League>(this.currentLeagueInitialState);

    @observable
    selectedLeague: Models.League = _.cloneDeep<Models.League>(this.selectedLeagueInitialState);

    @observable
    standings: Models.Standing[] | undefined = _.cloneDeep<Models.Standing[] | undefined>(this.standingInitialState);

    @observable
    overallStandings: Models.OverallStanding[] | undefined = _.cloneDeep<Models.OverallStanding[] | undefined>(this.overallStandingInitialState);

    @observable
    matchResults: Models.MatchResult[] | undefined = _.cloneDeep<Models.MatchResult[] | undefined>(this.matchResultsInitialState);

    @observable
    reportMatchLeagueDetails: Models.League | undefined = _.cloneDeep<Models.League | undefined>(this.reportMatchLeagueDetailsInitialState);

    @observable
    signUpData: Models.SignUp | undefined = _.cloneDeep<Models.SignUp | undefined>(this.signUpDataInitialState);


    constructor() {
        if (_.isNil(DataStore.instance)) {
            DataStore.instance = this;

            // DataStore.storage = localforage.createInstance({
            //     name: "default",
            // });
        }


        return DataStore.instance;
    }

    @action
    async initialize() {
        this.isInitializing = true;

        return await this.getLeagues()
            .then( res => {
                this.isInitialized = true;
                this.isInitializing = false;

                return res;
            })
            .catch(err => {
                console.log(err);
            })

    }

    @action
    async getLeagues() {
        this.shouldFetchLeagues = false;
        return await APIClient.getLeagues().then(res => {
            runInAction(() => {
                if (res.success) {
                    this.leagues = res.data;
                    this.shouldFetchLeagues = true;
                    this.setCurrentLeague();
                    this.setSelectedLeague();
                }
            });
            return res;
        })
    }

    @action
    async getLeagueStandings(leagueID: any, office: any) {
        this.shouldFetchStandings = false;
        return await APIClient.getLeagueStandings(leagueID, office).then(res => {
            runInAction(() => {
                if (res.success) {
                    this.standings = res.data;
                    this.shouldFetchStandings = true;
                }
            });
            return res;
        })
    }

    @action
    async getLeagueMatchResults(leagueID: any) {
        this.shouldFetchMatchResults = false;
        return await APIClient.getLeagueMatchResults(leagueID).then(res => {
            runInAction(() => {
                if (res.success) {
                    this.matchResults = _.orderBy(res.data, ['created_at'], ['desc']);
                    // this.matchResults = res.data.sort((d1: any, d2: any) => d2.created_at - d1.created_at);
                    this.shouldFetchMatchResults = true;
                }
            });
            return res;
        });
    }

    @action
    async getLeagueByID(leagueID: any) {
        this.shouldFetchLeagueDetails = false;
        return await APIClient.getLeagueByID(leagueID).then(res => {
            runInAction(() => {
                if (res.success) {
                    this.reportMatchLeagueDetails = res.data;
                    this.shouldFetchLeagueDetails = true;
                }
            });
            return res;
        });
    }

    @action
    async getOverallStandings() {
        this.shouldFetchOverallStandings = false;
        return await APIClient.getOverallStandings().then(res => {
            runInAction(() => {
                if (res.success) {
                    this.overallStandings = res.data;
                    this.shouldFetchOverallStandings = true;
                }
            });
            return res;
        })
    }

    @action
    async getSignUpData() {
        this.shouldFetchSignUpData = false;
        return await APIClient.getSignUpData().then(res => {
            runInAction(() => {
                if (res.success) {
                    this.signUpData = res.data;
                    this.shouldFetchSignUpData = true;
                }
            });
            return res;
        })
    }

    @action
    async submitMatch(match: any) {
        return await APIClient.submitMatch(match)
            .then(res => {
                return res;
            })
            .catch(err => console.log(err));
    }

    @action
    async submitSignUp(obj: any) {
        return await APIClient.submitSignUp(obj)
            .then(res => {
                return res;
            })
            .catch(err => console.log(err));
    }

    @action
    setCurrentLeague() {
        _.find(this.leagues, (league: any) => {
            if (league.active) {
               this.currentLeague = league;
            }
        });
        if (_.isNil(this.currentLeague.id)) {
            const timeSorted = _.sortBy(this.leagues, (league: any) => {
                return league.created_at - league.created_at;
            });

            this.currentLeague = timeSorted[0];
        }
    }

    @action
    setSelectedLeague(leagueID: any = this.currentLeague.id) {
        _.find(this.leagues, (league: any) => {
            if (league.id === leagueID) {
                this.selectedLeague = league;
            }
        });
    }

    @action
    clearStandings() {
        this.standings = _.cloneDeep<Models.Standing[] | undefined >(this.standingInitialState);
    }

    @action
    clearMatchResults() {
        this.matchResults = _.cloneDeep<Models.MatchResult[] | undefined>(this.matchResultsInitialState);
    }
}
