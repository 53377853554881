import * as React from "react";
import * as _ from "lodash";
import {Observer, observer} from "mobx-react";
import {MatchResultsProps, MatchResultsState} from "./MatchResultsInterface";
import {BaseComponent} from "../BaseComponent";
import * as ComponentFactory from "../ComponentFactory";
import {Input, Icon} from "antd";
import {AppMode} from "../../stores/AppStore";

@observer
export class MatchResults extends BaseComponent<MatchResultsProps, MatchResultsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadingMatchResults: false,
            currentLeague: this.dataStore.selectedLeague,
            searchInputValue: undefined,
        }

        this.loadMatchResults = this.loadMatchResults.bind(this);
        this.matchResultsContent = this.matchResultsContent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.searchResults = this.searchResults.bind(this);
    }

    componentDidMount() {
        this.loadMatchResults(this.props.league);
    }

    componentDidUpdate() {
        const {dataStore, state, props} = this;
        if (_.isNil(state.currentLeague) || state.currentLeague!.id !== props.league.id) {
            dataStore.clearMatchResults();
            this.setState({
                currentLeague: props.league,
                searchInputValue: undefined,
            }, () => {
                this.loadMatchResults(props.league);
            });
        }
    }

    handleSearchChange(event: any) {
        this.setState({
            searchInputValue: event.target.value,
        })
    }

    loadMatchResults(league: any) {
        const {dataStore, state} = this;
        const {shouldFetchMatchResults} = dataStore;
        if (!state.loadingMatchResults && shouldFetchMatchResults) {
            this.setState({
                loadingMatchResults: true,
            }, () => {
                dataStore.getLeagueMatchResults(league.id)
                    .then(res => {
                        if (res.success) {
                            this.setState({
                                loadingMatchResults: false,
                                currentLeague: league,
                            })
                        } else {
                            this.appStore.showMessage('error', res.statusText);
                        }
                    });
            });
        }
    }

    searchResults(matchResults: any) {
        const {state} = this;

        const r = matchResults!.filter((result: any) => {
            if (!_.isNil(result.winner) && !_.isNil(result.loser)) {
                const search = !_.isNil(state.searchInputValue) ? state.searchInputValue.toLowerCase() : "";
                if (result.winner.first_name!.toLowerCase().includes(search) ||
                    result.winner.last_name!.toLowerCase().includes(search) ||
                    result.loser.first_name!.toLowerCase().includes(search) ||
                    result.loser.last_name!.toLowerCase().includes(search)) {
                    return result;
                } else return undefined
            } else return undefined;
        });

        return r;
    }

    matchResultsContent() {
        const {dataStore, state} = this;
        const {matchResults} = dataStore;
        const isMobile = this.appStore.state.mode === AppMode.Mobile;
        const results = _.isNil(state.searchInputValue) ? matchResults : this.searchResults(matchResults);

        return (
            <div className={`match-results-main-container ${isMobile ? "mobile" : ""}`}>
                <Input className={`match-results-input ${isMobile ? "mobile" : ""}`}
                       placeholder={"Search Results"}
                       value={this.state.searchInputValue}
                       onChange={(e) => {this.handleSearchChange(e)}}
                       suffix={_.isNil(this.state.searchInputValue) ? <Icon type={"search"} /> : <Icon type={"close"} className={"match-results-input-clear"} onClick={() => this.setState({searchInputValue: undefined})} />}
                />
                {!_.isNil(results) && results.length > 0 && results.map((matchResult: any, index: number) => <ComponentFactory.MatchResultItem key={index} matchResult={matchResult}/>)}
                {(_.isNil(results) || results.length === 0) &&  <h3> No Match Results Found...</h3> }
            </div>
        )
    };

    protected renderContent() {
        const {state} = this;
        const {loadingMatchResults} = state;

        return (
            <>
                {loadingMatchResults && <ComponentFactory.Loading />}
                {!loadingMatchResults && this.matchResultsContent()}
            </>
        )
    }

    render() {
        return(
            <Observer>
                {() => this.renderContent()}
            </Observer>
        );
    }
}